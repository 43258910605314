import cx from 'classnames';
import memoizeState from 'memoize-state';
import {
    SEO_DESCRIPTION_DEFAULT_FALLBACK,
    SEO_TITLE_title_TEMPLATE_WITH_BRANDING,
    SEO_TITLE_DEFAULT_FALLBACK,
    SEO_TITLE_HOMEPAGE,
} from 'mk/autogenerated/translations/Page.ccb16de2a94c61087a5f6623458cc1d0'
import { faqUrl } from 'mk2/apps/faq/urls';
import { loginUrl, oldBrowserUrl, signupUrl } from 'mk2/apps/users/urls';
import { Footer } from 'mk2/components/Footer';
import { PageContentDesktopHeader } from 'mk2/components/PageContentDesktopHeader';
import { ScrollToTop } from 'mk2/components/ScrollToTop';
import AdSlotAnchorLeaderboard from 'mk2/containers/AdSlotAnchorLeaderboard/AdSlotAnchorLeaderboard';
import { AdSlotBranding } from 'mk2/containers/AdSlotBranding/AdSlotBranding';
import { AdSlotSticky } from 'mk2/containers/AdSlotSticky/AdSlotSticky';
import { AppName, Route } from 'mk2/containers/Application/Application';
import SiteMenu from 'mk2/containers/SiteMenu/SiteMenu';
import { UserAgentParsed } from 'mk2/helpers/detects';
import { hashCode } from 'mk2/helpers/hashes';
import { hasRequiredPermissions } from 'mk2/helpers/permissions';
import { organizationModrykonik } from 'mk2/helpers/structuredData';
import { url } from 'mk2/helpers/urls';
import Loadable from 'mk2/helpers/Loadable';
import PageDefinition, {
    AnchorLeaderboardProps,
    BrandingProps,
    HalfpageProps,
    PageLayout,
    RightContentSide,
} from 'mk2/pages/Page';
import { isFailure, LoadingState } from 'mk2/reducers/loadingState';
import { UserEntity } from 'mk2/schemas';
import { redirectInPWA, HistoryLocationState } from 'mk2/services/browserHistory';
import { interpolate } from 'mk2/services/i18n';
import { parse } from 'query-string';
import React from 'react';
import Helmet from 'react-helmet';
import ReduxToastr from 'react-redux-toastr';
import { RouteComponentProps, StaticContext } from 'react-router';
import { StickyContainer } from 'react-sticky';
import styles from './Page.mscss';

/* DashHeader is used only in adminclub :) - do not load it in normal pages */
const DashHeader = Loadable({
    loader: () => import('mk2/apps/dash/components/DashHeader' /* webpackChunkName: "dash.components.DashHeader" */),
    modules: ['mk2/apps/dash/components/DashHeader'],
    webpack: () => [ require.resolveWeak('mk2/apps/dash/components/DashHeader') ],
});

/* Same for BoTabs */
const BoTabs = Loadable({
    loader: () => import('mk2/apps/bo/components/BoTabs' /* webpackChunkName: "bo.components.BoTabs" */),
    modules: ['mk2/apps/bo/components/BoTabs'],
    webpack: () => [ require.resolveWeak('mk2/apps/bo/components/BoTabs') ],
});

const memoizeProps: (<F>(f: F) => F) =
    ((typeof window !== 'undefined') && !!(window as any).Proxy)  // browser, but not IE 11
        ? memoizeState
        : (f) => f;

// Cache results of methods from Page even if Props are equal but not same,
// for more details see https://github.com/theKashey/memoize-state
//
// Because cache size is 1, results are cached, only if user stays on the same Page. If she
// navigates to other Page, pageInstance parameter is different and results are recomputed.
const getPermissionRequiredCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getPermissionRequired(props));
const getMetaTitleCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getMetaTitle(props));
const getMetaDescriptionCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getMetaDescription(props));
const getMetaFaqRichSnippet = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getMetaFaqRichSnippet(props));
const getStructuredDataCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getStructuredData(props));
const getOpenGraphDataCached = memoizeProps((pageInstance: PageDefinition, props, baseUrl: string, currentUrl: string) => pageInstance.getOpenGraphData(props, baseUrl, currentUrl));
const getMetaRobotsCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getMetaRobots(props));
const getCanonicalUrlCached = memoizeProps((pageInstance: PageDefinition, baseUrl: string, props) => pageInstance.getCanonicalUrl(baseUrl, props));
const getPageLayoutCached = memoizeProps((pageInstance: PageDefinition, app: AppName, props) => pageInstance.getPageLayout(app, props));
const getRightContentSideCached = memoizeProps((pageInstance: PageDefinition, app: AppName, props) => pageInstance.getRightContentSide(app, props));
const getBrandingPropsCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getBrandingProps(props));
const getAnchorLeaderboardPropsCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getAnchorLeaderboardProps(props));
const getHalfpagePropsCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getHalfpageProps(props));
const getHeaderTitleCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getHeaderTitle(props));
const getHeaderTabsCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getHeaderTabs(props));
const getHeaderLeftCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getHeaderLeft(props));
const getHeaderRightCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getHeaderRight(props));
const getFooterCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.getFooter(props));
const isHeaderStickyCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.isHeaderSticky(props));
const hasMobileLogoCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.hasMobileLogo(props));
const hasHeaderBackButtonCached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.hasHeaderBackButton(props));
const hasPageHeaderPart1Cached = memoizeProps((pageInstance: PageDefinition, props) => pageInstance.hasPageHeaderPart1(props));
const getHeaderMenuSelectionCached = memoizeProps((pageInstance: PageDefinition, app: AppName, props) => pageInstance.getHeaderMenuSelection(app, props));
const hasPageFooterCached = memoizeProps((pageInstance: PageDefinition, app: AppName, props) => pageInstance.hasPageFooter(app, props));

interface OwnProps {
    app: AppName;
    baseUrl: string;
    isMobile: boolean;
    loadingState?: LoadingState;  // CeMi: posiela sa toto odniekal vobec?
    requestUser: UserEntity;
    requestUserPermissions: string[];
    userAgent: UserAgentParsed;
    hasIgnoredOldBrowserWarning: boolean;
    route: Route;
    pageInstance: PageDefinition<any, any, any>;
}

interface OwnState {
    hasPermission: boolean;
    hasSupportedBrowser: boolean;
}

type RouteProps = RouteComponentProps<{}, StaticContext, HistoryLocationState>;

export type Props = OwnProps & RouteProps;

class Page extends React.PureComponent<Props, OwnState> {

    public static whyDidYouRender = process.env.ENABLE_WHY_DID_YOU_RENDER;

    public static getDerivedStateFromProps(props: Props, state: OwnState) {
        const { requestUserPermissions, userAgent, pageInstance } = props;

        const permissions: string | string[] | boolean = getPermissionRequiredCached(pageInstance, props);
        const hasPermission = hasRequiredPermissions(requestUserPermissions, permissions);
        const hasSupportedBrowser = !userAgent?.flags?.oldBrowserVersion;

        return ((state.hasPermission !== hasPermission) || (state.hasSupportedBrowser !== hasSupportedBrowser))
            ? { hasPermission, hasSupportedBrowser }
            : null;  // nothing tu update
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            hasPermission: true,
            hasSupportedBrowser: true,
        };
    }

    public componentDidMount() {
        this.redirectIfMissingPermissionOrBrowser();

    }
    public componentDidUpdate(prevProps: Props) {
        this.redirectIfMissingPermissionOrBrowser();
    }

    public render() {
        const { pageInstance, app, ...rest } = this.props;
        const { baseUrl, location, history, requestUser } = this.props;

        if (!this.state.hasPermission) {
            if (typeof window === 'undefined') {
                // CeMi: zatial neviem o ziadnom inom rozumnom sposobe ako pustat side-effecty pre SSR
                // (ked sa nezavola componentDidMount alebo componentDidUpdate)
                this.redirectIfMissingPermissionOrBrowser();
            }

            // do not render inner content, until browser handles the url redirect
            return null;
        }

        // If loadingState is failure - disable ads
        const failureLoadingState = isFailure(rest.loadingState);

        const adKey = hashCode(`${location.pathname}${location.search.replace(/[?&]?(q|search)=[^&]+/, '')}`);

        const brandingProps: BrandingProps = !failureLoadingState
            ? getBrandingPropsCached(pageInstance, rest)
            : null;
        const branding = brandingProps
            ? <AdSlotBranding zone={brandingProps.zone} targeting={brandingProps.targeting} location={location} isMobile={rest.isMobile} />
            : null;

        const anchorLeaderboardProps: AnchorLeaderboardProps = !failureLoadingState
            ? getAnchorLeaderboardPropsCached(pageInstance, rest)
            : null;
        const anchorLeaderboardComponent = anchorLeaderboardProps
            ? (
                <AdSlotAnchorLeaderboard
                    zone={anchorLeaderboardProps.zone}
                    targeting={anchorLeaderboardProps.targeting}
                    requestUser={requestUser}
                    location={location}
                    format={'rectangle'}
                />
            )
            : null;

        const title = getMetaTitleCached(pageInstance, rest);
        const description = getMetaDescriptionCached(pageInstance, rest);
        const faqRichSnippet = getMetaFaqRichSnippet(pageInstance, rest);
        const currentUrl = `${location.pathname}${location.search}`;
        const opengraphData = getOpenGraphDataCached(pageInstance, rest, baseUrl, currentUrl);
        const structuredData = getStructuredDataCached(pageInstance, rest);
        const canonicalUrl = getCanonicalUrlCached(pageInstance, baseUrl, rest);

        const headerTitle = getHeaderTitleCached(pageInstance, rest);
        const headerLeft = getHeaderLeftCached(pageInstance, rest);
        const headerRight = getHeaderRightCached(pageInstance, rest);
        const tabs = getHeaderTabsCached(pageInstance, rest);

        const pageHeader = hasPageHeaderPart1Cached(pageInstance, rest)
            ? (app !== 'bo' ? (
                <React.Fragment>
                    <SiteMenu
                        menuSelection={getHeaderMenuSelectionCached(pageInstance, app, rest)}
                        currentUrl={currentUrl}
                        isHeaderSticky={isHeaderStickyCached(pageInstance, rest)}
                        headerHasBackBtn={hasHeaderBackButtonCached(pageInstance, rest)}
                        headerTitle={headerTitle}
                        headerLeft={headerLeft}
                        headerRight={headerRight}
                        headerTabs={tabs}
                        mobileShowLogo={hasMobileLogoCached(pageInstance, rest)}
                    />
                    {app === 'dash' && (
                        <DashHeader
                            menuSelection={getHeaderMenuSelectionCached(pageInstance, app, rest)}
                        />
                    )}
                </React.Fragment>
            ) : (
                <BoTabs
                    menuSelection={getHeaderMenuSelectionCached(pageInstance, app, rest)}
                />
            ))
            : (// Page has no header - but we still need toastr container
                <ReduxToastr
                    position="top-center"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                />
            );

        // na desktop je cast headeru (back a action button) priamo v content stranky v hlavnom stlpci
        const contentHeader = !rest.isMobile ? (
            <PageContentDesktopHeader
                headerTitle={headerTitle}
                headerLeft={headerLeft}
                headerRight={headerRight}
                tabs={tabs}
            />
        ) : null;

        const contentFooter = getFooterCached(pageInstance, rest);

        const content = (
            <React.Fragment>
                {contentHeader}

                {pageInstance.render(rest)}

                {contentFooter && (
                    <div className={styles.Page__contentFooter}>
                        {contentFooter}
                    </div>
                )}
            </React.Fragment>
        );

        const pageLayout: PageLayout = getPageLayoutCached(pageInstance, app, rest);
        let rightContent = null;
        if (pageLayout === PageLayout.MainContentWithRightColumn) {
            const renderRight = pageInstance.renderRight(rest);
            const halfpageProps: HalfpageProps = !rest.isMobile && !failureLoadingState
                ? getHalfpagePropsCached(pageInstance, rest)
                : null;

            if (renderRight || halfpageProps) {
                rightContent = (
                    <React.Fragment>
                        {renderRight}
                        {!!halfpageProps && (
                            <div className={cx(styles.Page__ad, styles[`Page__ad--${app}`])}>
                                <AdSlotSticky
                                    key={adKey}
                                    zone={halfpageProps.zone}
                                    targeting={halfpageProps.targeting}
                                    requestUser={rest.requestUser}
                                    location={location}
                                />
                            </div>
                        )}
                    </React.Fragment>
                );
            }
        }

        const rightContentSide = getRightContentSideCached(pageInstance, app, rest) === RightContentSide.RightSide ? 'right' : 'left';

        return (
            <React.Fragment> {/* <div className={styles.Page}> reduce DOM depth */}
                <Helmet>
                    <title>{title
                        // kvoli seo chceme, aby kazda stranka mala vzdy <title> a aby na konci title bolo
                        // '- Modry konik'. Google vie sam pridat '- Modry konik', ale pre Seznam to musime
                        // explicitne pridat my
                        ? (title !== SEO_TITLE_HOMEPAGE
                            ? interpolate(SEO_TITLE_title_TEMPLATE_WITH_BRANDING, {title})
                            : title // homepage uz ma 'Modry konik -' na zaciatku
                        ) : SEO_TITLE_DEFAULT_FALLBACK
                    }</title>
                    <meta name="description" content={description ?? SEO_DESCRIPTION_DEFAULT_FALLBACK} />
                    <meta name="robots" content={getMetaRobotsCached(pageInstance, rest)} />
                    {canonicalUrl && (
                        <link rel="canonical" href={canonicalUrl} />
                    )}
                    {opengraphData && Object.keys(opengraphData).map((ogKey: string) => {
                        const ogContent = opengraphData[ogKey];
                        return Array.isArray(ogContent) ? (
                            ogContent.map((cnt, index) => (
                                <meta key={`${ogKey}:${index}`} property={ogKey} content={cnt} />
                            ))
                        ) : (
                            <meta key={ogKey} property={ogKey} content={ogContent} />
                        );
                    })}
                    <script type="application/ld+json">
                        {JSON.stringify(organizationModrykonik())}
                    </script>
                    {faqRichSnippet &&
                        <script type="application/ld+json">
                            {faqRichSnippet}
                        </script>
                    }
                    {structuredData && structuredData.map((sd, index) => (sd
                        ? (
                            <script key={`sdata-${index}`} type="application/ld+json">
                                {JSON.stringify(sd)}
                            </script>
                        )
                        : null
                    ))}
                </Helmet>

                {!rest.isMobile && branding}

                <StickyContainer id="page-wrap" className={styles.Page__wrap}>
                    {anchorLeaderboardComponent}
                    <ScrollToTop location={location} history={history}>
                        <React.Fragment>
                            {pageHeader}

                            {rest.isMobile && branding}

                            <div
                                className={cx(
                                    styles.Page__content,
                                    styles[`Page__content--${PageLayout[pageLayout]}`],
                                    styles[`Page__content--${app}`],
                                )}
                            >
                                <div
                                    className={cx(
                                        styles.Page__mainContent,
                                        styles[`Page__mainContent--${PageLayout[pageLayout]}`],
                                        styles[`Page__mainContent--${app}`],
                                        styles[`Page__mainContent--${rightContentSide}`],
                                    )}
                                >
                                    {content}
                                </div>
                                {rightContent && (
                                    <div className={cx(styles.Page__rightContent, styles[`Page__rightContent--${app}`])}>
                                        {rightContent}
                                    </div>
                                )}
                            </div>

                            {!rest.isMobile && hasPageFooterCached(pageInstance, app, rest) && <Footer />}
                        </React.Fragment>
                    </ScrollToTop>
                </StickyContainer>
            </React.Fragment>
        );
    }

    private redirectIfMissingPermissionOrBrowser() {
        const { history, location, hasIgnoredOldBrowserWarning } = this.props;

        if (!this.state.hasPermission) {
            // ak uzivatelku posielam na stranku kde musi byt prihlasena, mozem cez
            // get parametere vynutit, ci sa jej ma zobrazit login alebo signup stranka
            // a ktora verzia login/signup stranky sa jej ma zobrazit
            const query = parse(location.search);
            const signinMode = query['signin-mode'] as string;
            const signinContentItem = query['signin-ci'] as string;

            const gotoParams = {
                next: `${location.pathname}${location.search}`,
                ci: signinContentItem,
            };
            const gotoUrl = signinMode === 'signup'
                ? url(signupUrl, {}, gotoParams)
                : url(loginUrl, {}, gotoParams);

            redirectInPWA(history, gotoUrl, 401);
        }
        if (!this.state.hasSupportedBrowser && !hasIgnoredOldBrowserWarning &&
            location.pathname !== oldBrowserUrl && !location.pathname.startsWith(faqUrl)) {
            const gotoUrl = url(oldBrowserUrl, {}, {next: `${location.pathname}${location.search}`});
            redirectInPWA(history, gotoUrl, 301);
        }
    }
}

export default Page;
